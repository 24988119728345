.header {
  position: relative;
  text-align: center;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0 10px;
}

.logo {
  padding-top: 4px;
  a:link:hover, a:visited:hover {
    border: 0;
    text-decoration: none;
  }
}

.version {
  flex: 0 0 auto;
  display: flex;

  div {
    color: white;
    font-weight: bold;
    padding: 2px 12px 0;
  }
}

.accountSection {
  background-color: #1f4f26;
  padding: 0 8px;
  color: white;
}
