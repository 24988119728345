.delim {
  color: #aaaaaa;
}

.navCurrent .delim {
  color: #999999;
}

.navItems {
  border-right: 1px dotted #dddddd;
  margin: 0 5px 0 12px;

  div {
    line-height: 26px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding-left: 2px;
    padding-right: 8px;
  }

  div.navCurrent {
    font-weight: bold;
    color: green;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    background-color: #efefef;
  }
}

.navVisited {
  color: #aaaaaa;
  cursor: pointer;
  &:hover {
    color: #a1d5ab;
  }
}

.navVisitedUnclickable {
  color: #aaaaaa;
}

.navRemaining {
  color: #777777;
}

@media (max-width: 800px) {
  .navVisited, .navRemaining {
    display: none;
  }
  .navItems div.navCurrent {
    border: 0;
  }
  .navItems {
    padding-right: 0 !important;
    margin: 0 12px 0 12px;
  }
}
