.footer {
  flex: 0 0 auto;
  width: 100%;
  padding: 25px 0;
  border-top: 1px dotted #cccccc;
  text-align: justify;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-right: 1px solid #cccccc;
      flex: 0 0 auto;

      &:last-child {
        border-right: 0;
      }

      &:hover {
        a {
          border-bottom: 0;
          color: #990000;
        }
        svg {
          fill: #990000;
        }
      }
    }

    a {
      color: #0066cc;
      padding: 0 20px;
      display: flex;
    }
    svg {
      fill: #0066cc;
      margin-right: 10px;
    }
  }
}
