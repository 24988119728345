.notification {
  margin-bottom: 10px;

  & > div {
    padding: 8px;
  }
  svg {
    float: right;
    cursor: pointer;
    margin-left: 10px;
  }
}

.notify {
  border: 1px solid #0058db;
  animation: notifyFadeIn 1.2s ease-in-out forwards;

  div {
    color: #0058db;
  }
  svg {
    color: #0058db;
    transition-duration: .5s;
    transition-property: color;
    &:hover {
      color: #003e91;
    }
  }
}

.error {
  border: 1px solid #cc0000;
  animation: errorFadeIn 1.2s ease-in-out forwards;

  div {
    color: #aa0000;
  }
  svg {
    color: #aa0000;
    transition-duration: .5s;
    transition-property: color;
    &:hover {
      color: #660000;
    }
  }
}

@keyframes notifyFadeIn {
  from {
    background-color: #c6e2ff;
  }
  to {
    background-color: #f2f8ff;
  }
}

@keyframes errorFadeIn {
  from {
    background-color: #ffbfbf;
  }
  to {
    background-color: #ffeded;
  }
}
