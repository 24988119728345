html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

body {
  background-image: url('../themes/default/images/top_row_green2x.png');
  background-size: 1px 275px;
  background-repeat: repeat-x;
  background-position: center top;
  text-align: center;
}

body, table, td, div, span, div, p, input, select, textarea, option {
  font: 11px Verdana, sans-serif;
  color: #333333;
}

body, table, td, span, div, p {
  line-height: 20px;
}

p {
  margin-top: 0;
}

ul {
  margin: 0;
  padding-left: 16px;
}

:global(#root) {
  height: 100%;
}

.page {
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 950px;
  text-align: left;
  margin: 0 auto;
}

.content {
  flex: 1;
  margin: 15px 10px 0 10px;
}

.pageContent {
  display: flex;
  flex-direction: row;

  .nav {
    flex: 0 0 auto;
    min-width: 192px;
    padding-right: 15px;
  }
  .body {
    flex: 1;

    // this allows child content to contain long truncated text without exceeding available space, see:
    // https://css-tricks.com/flexbox-truncated-text/
    min-width: 0;
    max-width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 800px) {
  .pageContent {
    display: block;
  }
  .nav {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #cccccc;
    border-right: 0;
  }
}

a:link, a:visited {
  color: #008800;
  text-decoration: none;

  &:hover {
    color: #990000;
    border-bottom: 1px solid #bb0000;
  }
}

.green {
  color: green;
}

.red {
  color: #cc0000;
}

.bold {
  font-weight: bold;
}

table.info {
  min-width: 300px;
  margin-bottom: 5px;
  td {
    border-bottom: 1px solid #efefef;
    padding: 2px;
  }
}

.label {
  color: #666666;
  padding-right: 15px;
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
  padding: 6px 12px 10px;
  border-bottom: 1px dotted #cccccc;
  font-family: Verdana, sans-serif;
}

h2 {
  color: #006600;
  font: 15px Verdana, sans-serif;
  margin: 0 0 10px;
  padding: 0;
}

h3 {
  font-size: 10pt;
  color: #006600;
  margin-top: 0;
}

.existingTables {
  border: 1px solid #cccccc;
  height: 250px;
  overflow-y: scroll;
  blockquote {
    margin: 10px;
  }
  pre {
    margin: 0;
    color: #999999;
  }
}

.configFileContents {
  background-color: #ffffff;
  border-radius: 3px;
  color: #555555;
  line-height: 12pt;
  padding: 5px;
  border: 1px solid #dddddd;
  margin-bottom: 5px;
}

.divider {
  border-bottom: 1px dotted #cccccc;
  margin-bottom: 10px;
}

span.fail {
  margin-left: 10px;
  background-color: #aa0000;
  padding: 1px 10px;
  color: white;
  border-radius: 4px;
}

span.pass {
  margin-left: 10px;
  background-color: green;
  padding: 1px 10px;
  color: white;
  border-radius: 4px;
}

ul.tips {
  padding-left: 16px;
  margin-left: 0;
  margin-bottom: 20px;

  li {
    line-height: 16px;
    span {
      color: #999999;
    }
  }
}

.table {
  .row {
    display: flex;
    margin-bottom: 5px;
    border-bottom: 1px solid #efefef;
    padding: 1px;
  }
}

.systemCheckTable {
  .label {
    flex: 0 0 180px;
  }
  .value {
    flex: 1 1 auto;
    overflow: hidden;
  }
  .result {
    flex: 0 0 100px;
    text-align: center;
  }
  .fullValue {
    flex: 1 1 auto;
  }
}

.dbSettingsTable {
  .label {
    flex: 0 0 200px;
  }
  .value {
    flex: 1 1 auto;
    overflow: hidden;
  }
}